import React, { useState, useEffect } from "react";
import "../../styles/achievement.css";
import { motion } from "framer-motion";
import api from "../Axios/api"; 
function Achievement() {
  const [achievements, setAchievements] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  useEffect(() => {
    // Fetch achievements from the backend
    const fetchAchievements = async () => {
      try {
        const response = await api.get("/getAchievement");
        setAchievements(response.data.achievements); 
      } catch (err) {
        setError("Failed to fetch achievements. Please try again.");
        console.error("Error fetching achievements:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAchievements();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>{error}</div>; // Error state
  }

  return (
    <>
      <div className="achievement-page-section">
        <div className="achievement-page-overlay">
          <h1>Achievement</h1>
        </div>
      </div>

      <div className="achievement-page">
        <motion.h1
          className="achievement-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Achievements
        </motion.h1>

        <div className="achievement-grid">
          {achievements.map((achievement, index) => (
            <motion.div
              key={index}
              className="achievement-card"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <img
                src={achievement.imageUrl} 
                alt={achievement.title} 
                className="achievement-image"
              />
              <h2 className="achievement-card-title">{achievement.title}</h2>
              <p className="achievement-card-description">{achievement.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Achievement;
