import React, { useEffect, useState } from 'react';
import "../../styles/gallery.css";
import { motion } from 'framer-motion';
import api from '../Axios/api';

function Gallery() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch photos from the API
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await api.get("/getGallary");
        if (response.status === 200) {
          setPhotos(response.data.photos); 
        } else {
          setError("Failed to load gallery images.");
        }
      } catch (err) {
        setError("Error fetching photos. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="gallery-section-page">
        <div className="gallery-overlay">
          <h1>Gallery</h1>
        </div>
      </div>

      <div className="gallery-container">
        <motion.h1
          className="gallery-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          School Memories
        </motion.h1>

        <div className="media-grid">
          {photos.map((photo, index) => (
            <motion.div
              key={index}
              className="media-item"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <motion.img
                src={photo.imageUrl} 
                alt={`Gallery Image ${index + 1}`}
                className="gallery-media"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Gallery;
