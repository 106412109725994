import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:5000',
  baseURL: 'https://backend-rhoh.onrender.com',
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
