import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/header';
import Footer from './Components/footer';
import Popup from './Components/notification/popup';
import Home from './Components/home';
import About from './Components/about/about';
import Booklist from './Components/booklist/booklist';
import Gallery from './Components/gallery/gallery';
import Achievement from './Components/achievement/achievement';
import Contact from './Components/contact/contact';
import Faculty from './Components/faculty/faculty';
import EnquiryForm from './Components/enquiry/enquiry';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/booklist' element={<Booklist />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/achievement' element={<Achievement />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/faculty' element={<Faculty />} />
          <Route path='/enquiry' element={<EnquiryForm />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App;