import React, { useState } from "react";
import "../../styles/enquiry.css";
import { motion } from "framer-motion";
import api from "../Axios/api";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

function EnquiryForm() {
  const [formData, setFormData] = useState({
    childName: "",
    forClass: "",
    mobileNumber: "",
    email: "",
    guardianName: "",
    message: "" // 'message' field is already here
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await api.post("/addEnquiry", {
        name: formData.childName,
        number: formData.mobileNumber,
        email: formData.email,
        fatherName: formData.guardianName,
        division: formData.forClass,
        message: formData.message
      });

      console.log("Form submitted:", response.data);
      
      // Show success message using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Thank you for contacting us!',
        text: 'We have received your enquiry.',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/'); // Navigate to home page after clicking 'OK'
      });

      setFormData({
        childName: "",
        forClass: "",
        mobileNumber: "",
        email: "",
        guardianName: "",
        message: "",
      });
    } catch (err) {
      console.error("Error submitting enquiry:", err);
      setError("Failed to submit enquiry. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="enquiry-section">
        <div className="enquiry-overlay">
          <h1>Enquiry</h1>
        </div>
      </div>

      <div className="enquiry-form-section">
        <motion.div
          className="enquiry-form-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2>Enquiry Form</h2>
          <form onSubmit={handleSubmit} className="enquiry-form">
            {["childName", "mobileNumber", "email", "guardianName", "message"].map((field, index) => (
              <motion.div
                key={field}
                className="form-group"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <label>
                  {field === "childName" ? "Name of Child" :
                    field === "mobileNumber" ? "Mobile Number" :
                      field === "email" ? "Email" :
                        field === "message" ? "Message" : "Guardian's Name"}
                </label>
                <motion.input
                  type={field === "mobileNumber" ? "tel" : field === "email" ? "email" : "text"}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  whileFocus={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                  required
                />
              </motion.div>
            ))}

            <motion.div
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 3 * 0.1, duration: 0.5 }}
            >
              <label htmlFor="forClass">For Class:</label>
              <motion.select
                name="forClass"
                id="forClass"
                value={formData.forClass}
                onChange={handleChange}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                required
              >
                <option value="" disabled selected>Select Class</option>
                <option value="LKG">LKG</option>
                <option value="UKG">UKG</option>
                <option value="Class 1">Class 1</option>
                <option value="Class 2">Class 2</option>
                <option value="Class 3">Class 3</option>
                <option value="Class 4">Class 4</option>
                <option value="Class 5">Class 5</option>
                <option value="Class 6">Class 6</option>
                <option value="Class 7">Class 7</option>
                <option value="Class 8">Class 8</option>
                <option value="Class 9">Class 9</option>
                <option value="Class 10">Class 10</option>
                <option value="Class 11">Class 11</option>
                <option value="Class 12">Class 12</option>
              </motion.select>
            </motion.div>

            {error && <p className="error-message">{error}</p>}

            <motion.button
              type="submit"
              disabled={loading}
              whileHover={{ scale: 1.05, backgroundColor: "#0047AB" }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.3 }}
            >
              {loading ? "Submitting..." : "Submit"}
            </motion.button>
          </form>
        </motion.div>
      </div>
    </>
  );
}

export default EnquiryForm;
