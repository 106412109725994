import React, { useState, useEffect } from 'react';
import './popup.css'
import api from '../Axios/api';

const Popup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [message, setMessage] = useState(''); // Message to show in the popup

  // Function to open the popup
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // Fetch notifications on component mount
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await api.get('getnoti'); 
        if (response.data.data && response.data.data.length > 0) {
          setNotifications(response.data.data);
          setMessage('You have new notifications!');
        } else {
          setMessage('No notifications for today.');
        }
      } catch (error) {
        console.error('Error fetching notifications:', error.message);
        setMessage('Failed to load notifications.');
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div>
      <button onClick={handleOpenPopup}>Show Notifications</button>

      {/* Pass the notifications and message to Popup */}
      <Popup
        message={message}
        isOpen={isPopupOpen}
        closePopup={handleClosePopup}
      />

      {/* Display notifications if available */}
      {isPopupOpen && notifications.length > 0 && (
        <div className="notification-list">
          <ul>
            {notifications.map((notification, index) => (
              <li key={index}>{notification.message}</li> // Display each notification message
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Popup;
