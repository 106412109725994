import React, { useState, useEffect } from 'react';
import "../../styles/faculty.css";
import { motion } from 'framer-motion';
import api from '../Axios/api'; // Make sure to import the api instance

function Faculty() {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the teacher data when the component mounts
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await api.get('/getTeacher');
        setTeachers(response.data.teachers); 
        setLoading(false);
      } catch (err) {
        setError("Failed to load teacher data.");
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  return (
    <>
      <div className="faculty-page-section">
        <div className="faculty-page-overlay">
          <h1>Faculty</h1>
        </div>
      </div>

      <div className="faculty-page">
        <motion.h1
          className="faculty-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Team Members
        </motion.h1>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="faculty-grid">
            {teachers.map((teacher, index) => (
              <motion.div
                key={index}
                className="faculty-card"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <img
                  src={teacher.imageUrl} 
                  alt={teacher.name}
                  className="faculty-image"
                />
                <h2 className="faculty-name">{teacher.name}</h2>
                <p className="faculty-position">{teacher.position}</p>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Faculty;
