import React, { useState,useEffect } from "react";
import Slider from "react-slick";
import "../styles/home.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bg from "../Components/Assets/bg3.jpeg";
import bg1 from "../Components/Assets/bg9.jpeg";
import bg2 from "../Components/Assets/bg5.jpeg";
import { motion } from 'framer-motion';
import achievmentimg1 from "../Components/Assets/achievment10.jpeg";
import achievmentimg2 from "../Components/Assets/achievment3.jpeg";
import achievmentimg3 from "../Components/Assets/achievement.jpeg";

import class1pdf from "../Components/Assets/class 1.pdf";
import class2pdf from "../Components/Assets/class 2.pdf";
import class3pdf from "../Components/Assets/class 3.pdf";
import class4pdf from "../Components/Assets/class 4.pdf";
import class5pdf from "../Components/Assets/class 5.pdf";
import class6pdf from "../Components/Assets/class 6.pdf";
import class7pdf from "../Components/Assets/class 7.pdf";
import class8pdf from "../Components/Assets/class 8.pdf";
import class9and10pdf from "../Components/Assets/Class9and10.pdf";
import class11and12commercepdf from "../Components/Assets/class 11& 12 commerce.pdf";
import class11and12pdf from "../Components/Assets/class 11&12.pdf";

import feestructure from "../Components/Assets/feestructure.pdf";

// import feestructurepdf from "../Components/Assets/fee-structure.pdf"

import { format, addMonths, subMonths, addDays, subDays, isSameDay, isToday, startOfWeek, addWeeks, subWeeks, startOfMonth, endOfMonth } from "date-fns";

import aboutimg from "../Components/Assets/about.jpeg";
import Popup from "./notification/popup";
import api from './Axios/api'
const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <i className="fas fa-chevron-right"></i> {/* Use Font Awesome or SVG */}
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <i className="fas fa-chevron-left"></i> {/* Use Font Awesome or SVG */}
    </div>
  );
};


const Hero = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
    const [photos, setPhotos] = useState([]);
  const [view, setView] = useState('Month');

  const handleNext = () => {
    if (view === 'Month') {
      setCurrentDate(addMonths(currentDate, 1));
    } else if (view === 'Week') {
      setCurrentDate(addWeeks(currentDate, 1));
    } else {
      setCurrentDate(addDays(currentDate, 1));
    }
  };

  const handlePrev = () => {
    if (view === 'Month') {
      setCurrentDate(subMonths(currentDate, 1));
    } else if (view === 'Week') {
      setCurrentDate(subWeeks(currentDate, 1));
    } else {
      setCurrentDate(subDays(currentDate, 1));
    }
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const renderDaysInMonth = () => {
    const days = [];
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    let day = startOfWeek(start, { weekStartsOn: 0 });

    while (day <= end || days.length < 42) {
      days.push(new Date(day));
      day = addDays(day, 1);
    }
    return days;
  };

  const renderDaysInWeek = () => {
    const days = [];
    let day = startOfWeek(currentDate, { weekStartsOn: 0 });

    for (let i = 0; i < 7; i++) {
      days.push(addDays(day, i));
    }
    return days;
  };

  const events = [
    { date: new Date(2024, 9, 1), title: "School Opening" },
    { date: new Date(2024, 9, 15), title: "PTM" },
  ];

  const renderView = () => {
    if (view === 'Month') {
      return renderDaysInMonth();
    } else if (view === 'Week') {
      return renderDaysInWeek();
    } else {
      return [currentDate];
    }
  };
  useEffect(() => {
    // Show the popup every 5 minutes
    const interval = setInterval(() => {
      setShowPopup(true);
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const achievements = [
    {
      title: "Award 1",
      description: "Description for award 1",
      year: "2021",
      image: achievmentimg1
    },
    {
      title: "Award 2",
      description: "Description for award 2",
      year: "2022",
      image: achievmentimg2
    },
    {
      title: "Award 3",
      description: "Description for award 3",
      year: "2023",
      image: achievmentimg3
    },
  ];

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await api.get("/getGallary");
        if (response.status === 200) {
          setPhotos(response.data.photos); 
        } else {
          console.log("Failed to load gallery images.");
        }
      } catch (err) {
        console.log("Error fetching photos. Please try again.");
      } 
    };

    fetchPhotos();
  }, []);

 
  const teamsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
 const [teachers, setTeachers] = useState([]);
 
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await api.get('/getTeacher');
        setTeachers(response.data.teachers); 
        
      } catch (err) {
        console.log("Failed to load teacher data.");
        
      }
    };

    fetchTeachers();
  }, []);
  return (
    <>
      <div className="hero">
        <Slider {...settings}>
          <div className="hero-slide">
            <img src={bg1} alt="Slide 1" />
            <div className="hero-overlay"></div>
            <div className="hero-content">
              <h1>WELCOME TO </h1>
              <h1>GYAN NIKETAN BURHAR</h1>
            </div>
            {showPopup && <Popup onClose={handleClosePopup} />}
          </div>
          <div className="hero-slide">
            <img src={bg2} alt="Slide 2" />
            <div className="hero-overlay"></div>
            <div className="hero-content">
              <h1>Through knowledge at Gyanniketan, </h1>
              <h1>Dreams are transformed into reality.</h1>
            </div>
          </div>
          <div className="hero-slide">
            <img src={bg} alt="Slide 3" />
            <div className="hero-overlay"></div>
            <div className="hero-content">
              <h1>At Gyanniketan, we empower future generations,</h1>
              <h1>Where knowledge builds the strength within.</h1>
            </div>
          </div>
        </Slider>
      </div>

      {/* hero section end */}


      {/* about us section start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-4 heading">Why Us</h1>
              <p className="about-para">
                At Gyanniketan, we are committed to nurturing young minds in a dynamic and supportive environment. Our mission is to provide a well-rounded education that emphasizes not only academic excellence but also the development of character and values.
              </p>
              <p className="mb-4 about-para">
                Our dedicated faculty is passionate about fostering a love for learning, guiding students to discover their strengths and interests. We celebrate each child’s unique abilities and work collaboratively with families to ensure that every student reaches their fullest potential.
              </p>
              <p className="mb-4 about-para">
                At Gyanniketan, we take pride in our holistic approach, offering diverse extracurricular activities in sports, arts, and leadership programs that complement our academic curriculum. Our students consistently achieve remarkable results, contributing to our esteemed reputation within the community.
              </p>
              <div className="row g-4 align-items-center">
                <div className="col-sm-6">
                  <a className="btn btn-primary rounded-pill py-3 px-5" href="/about">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    className="img-fluid w-80 rounded-5 p-3 center-image"
                    src={aboutimg}
                    alt="About 1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about us section end */}


      {/* admission open section start */}
      <section className="info-section">
        <div className="container">
          <h2 className="admission-section-heading">Admissions Open 2025-26</h2>
          <div className="heading-underline"></div>

          <div className="row">
            <div className="col-md-6">
              <div className="info-box fade-in">
                <h3>Admissions Open</h3>
                <p>Apply now for the academic year 2025-26 and secure a bright future for your child.</p>
              </div>
            </div>

            <div className="col-md-6">
              <a href={feestructure}>
                <div className="info-box fade-in">
                  <h3>Fee Structure</h3>
                  <p>Learn about the fee structure for the upcoming academic year and plan ahead.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* admission open section end */}


      {/* achievment section start */}
      <div className="achievements-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">OUR ACHIEVEMENTS</h2>
          <div className="row">
            {achievements.map((achievement, index) => (
              <div
                className="col-md-4 col-sm-6 mb-4 wow fadeInUp"
                data-wow-delay={`${index * 0.1}s`}
                key={index}
              >
                <div className="achievement-card">
                  <img
                    src={achievement.image}
                    alt={achievement.title}
                    className="achievement-image"
                  />
                  <h3>{achievement.title}</h3>
                  <p>{achievement.description}</p>
                  <span className="year">{achievement.year}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* achievment section end */}


      {/* our team section start */}
      <div className="our-team-wrapper m-auto">
        <div className="mt-20">
          <h2 className="our-team-heading text-center text-3xl font-bold mb-10">OUR TEAM</h2>
          <Slider {...teamsettings}>
          {teachers.map((teacher, index) => (
              <motion.div
                key={index}
                className="faculty-card"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <img
                  src={teacher.imageUrl} 
                  alt={teacher.name}
                  className="faculty-image"
                />
                <h2 className="faculty-name">{teacher.name}</h2>
                <p className="faculty-position">{teacher.position}</p>
              </motion.div>
            ))}
          </Slider>
        </div>
      </div>
      {/* our team section end */}

      {/* booklist section start */}
      <section className="class-section py-5">
        <div className="container">
          <h2 className="section-heading text-center">BOOK LIST 2024 - 2025</h2>
          <div className="row">
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS I</h3>
                <a href={class1pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS II</h3>
                <a href={class2pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS III</h3>
                <a href={class3pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS IV</h3>
                <a href={class4pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS V</h3>
                <a href={class5pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS VI</h3>
                <a href={class6pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS VII</h3>
                <a href={class7pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS VIII</h3>
                <a href={class8pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS IX & X</h3>
                <a href={class9and10pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS XI & XII (COMMERCE)</h3>
                <a href={class11and12commercepdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="class-box">
                <h3>CLASS XI & XII (SCIENCE)</h3>
                <a href={class11and12pdf} className="btn btn-primary">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* booklist section end */}


      {/* gallery start */}
      <div className="gallery-section py-5">
        <div className="container">
          <h2 className="Gallery-section-heading">GALLERY</h2>
          <div className="gallery-heading-line"></div>
          <div className="gallery-grid">
          {photos.map((photo, index) => (
            <motion.div
              key={index}
              className="media-item"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              <motion.img
                src={photo.imageUrl} 
                alt={`Gallery Image ${index + 1}`}
                className="gallery-media"
              />
            </motion.div>
          ))}
          </div>
        </div>
      </div>
      {/* gallery end */}


      {/* calender section start */}
      <div className="calendar-wrapper">
        <div className="calendar">
          <h1 className="calendar-heading">SCHOOL CALENDAR</h1>
          <header className="calendar-header">
            <div className="button-group">
              <button onClick={handlePrev} className="nav-btn">{"<"}</button>
              <button onClick={handleToday} className="today-btn">Today</button>
              <button onClick={handleNext} className="nav-btn">{">"}</button>
            </div>
            <h2>{format(currentDate, "MMMM yyyy")}</h2>
            <div className="view-toggle">
              <button onClick={() => setView('Month')} className={view === 'Month' ? 'active' : ''}>Month</button>
              <button onClick={() => setView('Week')} className={view === 'Week' ? 'active' : ''}>Week</button>
              <button onClick={() => setView('Day')} className={view === 'Day' ? 'active' : ''}>Day</button>
            </div>
          </header>
          <div className={`calendar-grid ${view.toLowerCase()}`}>
            {renderView().map((day) => {
              const event = events.find(event => isSameDay(event.date, day));
              return (
                <div key={day} className={`calendar-day ${isToday(day) ? "today" : ""}`}>
                  {format(day, view === 'Day' ? "EEEE, MMM d" : "d")}
                  {event && <div className="event">{event.title}</div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* calender section end */}

    </>
  );
};

export default Hero;